import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import { withNotificationContext, NotificationContext } from 'clouden-components/context/notification'
import cloudenPingImage1 from '../images/cloudenping_1.png'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'

interface HomepageProps extends WithTranslation {
  location: any
  notificationContext: NotificationContext
}

class Homepage extends React.Component<HomepageProps> {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title={"Welcome"} description="Clouden Ping" />
        <div className="container">
          <div className="bg-white p-4">
            <div className="jumbotron text-center">
              <h1><Trans i18nKey="home_title">Clouden Ping</Trans></h1>
              <p className="mb-2"><Trans i18nKey="home_description">Monitor your websites with pay-as-you-go pricing. No monthly payments.</Trans></p>
              <h4 className="mt-1 mb-3"><Trans i18nKey="home_price">0,001€ per ping</Trans></h4>
              <p><Link to="/dashboard/?mode=sign-up" className="btn btn-lg btn-primary"><Trans i18nKey="home_try_it">Try It Now</Trans></Link></p>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-4">
                  <h2><Trans i18nKey="home_minimal_title">Minimal example</Trans></h2>
                  <div><Trans i18nKey="home_minimal_description">Monitor one website with a daily ping.</Trans></div>
                  <p><strong><Trans i18nKey="home_minimal_cost">0,03€ / month</Trans></strong></p>
                </div>
                <div className="col-lg-4">
                  <h2><Trans i18nKey="home_typical_title">Typical example</Trans></h2>
                  <div><Trans i18nKey="home_typical_description">Monitor two websites with hourly pings.</Trans></div>
                  <p><strong><Trans i18nKey="home_typical_cost">1,44€ / month</Trans></strong></p>
                </div>
                <div className="col-lg-4">
                  <h2><Trans i18nKey="home_advanced_title">Advanced example</Trans></h2>
                  <div><Trans i18nKey="home_advanced_description">Monitor five websites with 5-minute pings.</Trans></div>
                  <p><strong><Trans i18nKey="home_advanced_cost">43,20€ / month</Trans></strong></p>
                </div>
              </div>
            </div>
            <br/>
            <div>
              <img className="img-fluid rounded" src={cloudenPingImage1}/>
            </div>
            <div className="row">
              <div className="col-lg-4 mt-4">
                <h2><Trans i18nKey="home_safe_title">Safe</Trans></h2>
                <p><Trans i18nKey="home_safe_text1">Pay safely using Stripe. No monthly charge! Add as many credits as you like - the minimum is only 1,99 EUR.</Trans></p>
                <p><Trans i18nKey="home_safe_text2">Optionally enable auto-charge to refill your credits when running low.</Trans></p>
              </div>
              <div className="col-lg-4 mt-4">
                <h2><Trans i18nKey="home_flexible_title">Flexible</Trans></h2>
                <p><Trans i18nKey="home_flexible_text1">Choose ping frequency of 5 minutes, 1 hour or 1 day. Get email notifications when your services go down.</Trans></p>
                <p><Trans i18nKey="home_flexible_text2">Add up to 100 HTTP or HTTPS URLs with individual ping frequencies (max response size 10MB).</Trans></p>
              </div>
              <div className="col-lg-4 mt-4">
                <h2><Trans i18nKey="home_reliable_title">Reliable</Trans></h2>
                <p><Trans i18nKey="home_reliable_text1">Built using Serverless &amp; Amazon AWS technologies by Clouden's team of experienced cloud engineers.</Trans></p>
                <p><Trans i18nKey="home_reliable_text2">All user information is encrypted and stored securely in the AWS cloud in Ireland, EU.</Trans></p>
              </div>
            </div>
            <div className="row" style={{ marginTop: '0.3em' }}>
              <div className="col-lg-12">
                <hr/>
              </div>
            </div>
            <div className="row" style={{ marginTop: '0em', marginBottom: '1em' }}>
              <div className="col-lg-12 text-center contact-info">
              <a href="https://twitter.com/cloudeninc" target="_blank" rel="noopener"><span className="fab fa-twitter-square"></span></a>
                  {' '}<a href="https://www.facebook.com/cloudeninc/" target="_blank" rel="noopener"><span className="fab fa-facebook-square"></span></a>
                  {' '}Clouden Oy, Minna Canthin katu 16 A 24, 00250 Helsinki, Finland
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withTranslation()(withNotificationContext(Homepage))
